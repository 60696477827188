import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import powerButton from "../images/hvac/power-button.webp";
import modeButton from "../images/hvac/mode-button.webp";
import modeCycle from "../images/hvac/mode-cycling.webp";
import tempUp from "../images/hvac/temp-up.webp";
import tempDown from "../images/hvac/temp-down.webp";
import fanButton from "../images/hvac/fan-button.webp";
import fanCycling from "../images/hvac/fan-cycling.webp";
import upDown from "../images/hvac/up-down-airflow.webp";
import upDownCycling from "../images/hvac/up-down-airflow-cycling.webp";
import leftRight from "../images/hvac/left-right-airflow.webp";
import leftRightCycling from "../images/hvac/left-right-airflow-cycling.webp";
import powerfulButton from "../images/hvac/powerful-button.webp";

const h1ClassNames = "text-center font-bold text-3xl pb-5";
const h2ClassNames = "italic font-bold text-2xl pt-4";
const h3ClassNames = "pt-2 font-bold text-xl";
const pClassNames = "pt-4 pl-2 text-lg";
const ulClassNames = "pl-2";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-lg";
const buttonImageClasses =
  "inline pl-2 pr-2 w-1/4 sm:w-1/4 md:w-1/4 lg:w-1/12  ";

const HvacInstructionsPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Heat & A/C Operating Instructions</h1>
          <p className={pClassNames}>
            The Overlook Chalet uses a state-of-the-art Mitsubishi heat pump
            system for both <b>heating</b> and <b>cooling</b>. Each room has
            it's own climate control.
          </p>
          <p
            className={
              pClassNames + " border-red-500 border-2 text-center p-2 pb-4"
            }
          >
            <b>
              <em>
                Please be sure to remove the remote from the wall and point it
                at the unit you intend to adjust.
              </em>
            </b>
          </p>
          <h2 className={h2ClassNames}>Basic Operation</h2>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Remove the remote from the wall and point it at the wall-mounted
              unit.
            </li>
            <li className={liClassNames}>
              Press{" "}
              <img
                className={buttonImageClasses}
                src={powerButton}
                alt="power-button"
              />
              to start operation
            </li>
            <li className={liClassNames}>
              Press{" "}
              <img className={buttonImageClasses} src={modeButton} alt="mode" />{" "}
              to select operation mode. Each press changes mode in the following
              order:
            </li>
            <img
              className="w-full lg:w-1/2 md:w-full sm:w-full pt-2 pb-2"
              src={modeCycle}
              alt="mode-cycle"
            />
            <li className={liClassNames}>
              Press{" "}
              <img className={buttonImageClasses} src={tempUp} alt="temp-up" />{" "}
              or
              <img
                className={buttonImageClasses}
                src={tempDown}
                alt="temp-down"
              />
              to set the temperature. Each press raises or lowers the
              temperature by 1°F (0.5°C).
            </li>
            <li className={liClassNames}>
              Press{" "}
              <img className={buttonImageClasses} src={fanButton} alt="fan" />
              to select fan speed. Each press changes fan speed in the following
              order:
            </li>
            <img
              className="w-full lg:w-1/2 md:w-full sm:w-full pt-2 pb-2"
              src={fanCycling}
              alt="fan-cycle"
            />
          </ol>
          <h2 className={h2ClassNames}>Airflow Direction</h2>
          <h3 className={h3ClassNames}>Up and Down</h3>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Press <img className={buttonImageClasses} src={upDown} />
              to select airflow direction. Each press changes airflow direction
              in the following order:
            </li>
            <img
              className="w-full lg:w-1/2 md:w-full sm:w-full pt-2 pb-2"
              src={upDownCycling}
              alt="up-down"
            />
          </ol>
          <h3 className={h3ClassNames}>Left and Right</h3>
          <ol className={olClassNames}>
            <li className={liClassNames}>
              Press{" "}
              <img
                className={buttonImageClasses}
                src={leftRight}
                alt="left-right"
              />
              to select airflow direction. Each press changes airflow direction
              in the following order:
            </li>
            <img
              className="w-full lg:w-1/2 md:w-full sm:w-full pt-2 pb-2"
              src={leftRightCycling}
              alt="left-right-cycle"
            />
          </ol>
          <h2 className={h2ClassNames}>Powerful Mode</h2>
          <p className={pClassNames}>
            We know it can get pretty cold up there in the winter. That's why we
            selected units with a mode for extreme temperatures.
          </p>

          <ol className={olClassNames}>
            <li className={liClassNames}>
              Remove the remote from the wall and point it at the wall-mounted
              unit.
            </li>

            <li className={liClassNames}>
              Press{" "}
              <img
                className={buttonImageClasses}
                src={powerfulButton}
                alt="powerful"
              />{" "}
              during COOL or HEAT mode to start POWERFUL operation. Powerful
              operation is automatically turned off after 15 minutes after which
              high fan operation will resume.
            </li>
          </ol>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default HvacInstructionsPage;
